import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import WindowHandler from './components/WindowHandler';


function App() {
  return (
    <div>
      <Router>
            <Routes>
                <Route path="/" element={<WindowHandler />}/>
              </Routes>
      </Router>
    </div>
  );
}

export default App;
