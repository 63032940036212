import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import SvgAnalog from '../svg/SvgAnalog';

export default class AquasenseOverView extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false
      }
    }

  jsonGet(jsondata, key){
      try{
        return jsondata[key];
      }catch(error){
        //console.error(error);
        return {};
      }
    }

  getCurrentTime(){
    let dateObj = new Date();
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }


  render() {
    let aqs0677ComError = this.props.listComErrorSystem.includes("aqs-06-81");
    let aqs0678ComError = this.props.listComErrorSystem.includes("aqs-06-82");
    let aqs0679ComError = this.props.listComErrorSystem.includes("aqs-06-83");
    let aqs0680ComError = this.props.listComErrorSystem.includes("aqs-06-84");
    let nh30102ComError = this.props.listComErrorSystem.includes("nh3-01-06");
    let nh30103ComError = this.props.listComErrorSystem.includes("nh3-01-07");
    let nh30104ComError = this.props.listComErrorSystem.includes("nh3-01-08");
    let nh30105ComError = this.props.listComErrorSystem.includes("nh3-01-09");
    let aqs0677redCrossWhenComError = <></>;
    if(aqs0677ComError){
      aqs0677redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M120 310 L505 560 M120 560 L505 310"/>
    }
    let aqs0678redCrossWhenComError = <></>;
    if(aqs0678ComError){
      aqs0678redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M550 310 L935 560 M550 560 L935 310"/>
    }
    let aqs0679redCrossWhenComError = <></>;
    if(aqs0679ComError){
      aqs0679redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M980 310 L1365 560 M980 560 L1365 310"/>
    }
    let aqs0680redCrossWhenComError = <></>;
    if(aqs0680ComError){
      aqs0680redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1410 310 L1795 560 M1410 560 L1795 310"/>
    }
    let nh30102redCrossWhenComError = <></>;
    if(nh30102ComError){
      nh30102redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M120 600 L505 850 M120 850 L505 600"/>
    }
    let nh30103redCrossWhenComError = <></>;
    if(nh30103ComError){
      nh30103redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M550 600 L935 850 M550 850 L935 600"/>
    }
    let nh30104redCrossWhenComError = <></>;
    if(nh30104ComError){
      nh30104redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M980 600 L1365 850 M980 850 L1365 600"/>
    }
    let nh30105redCrossWhenComError = <></>;
    if(nh30105ComError){
      nh30105redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1410 600 L1795 850 M1410 850 L1795 600"/>
    }

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    return(

      <div style={{height:"100%", width:"100%",backgroundColor:backgroundColor}}>

        <div style={{width:"100%", height:"100%"}}>
         <svg  style={{ width:"100%",height:"100%"}} viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
            <text x="960" y="50" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >Overview</text>
            <text x="1900" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"end",textAnchor:"end"}} >{this.getCurrentTime()}</text>

            {/* Border for aqs-06-81 and nh3-01-06 */} 
            <text x="312.5" y="200" fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >Well 4</text>

            <text x="312.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-81</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="120" y="260" width="385" height="350" ry="20"/>
  
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={185} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={310} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={435} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={247.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={372.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={185} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={310} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={435} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-81/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  

            {aqs0677redCrossWhenComError}

            <text x="312.5" y="635" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >NH3-01-06</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="120" y="650" width="385" height="250" ry="20"/>
            <SvgAnalog objectName="TAN" objectDescription="TAN mg/l" pvMin={0.0} pvMax={50.0} x={185} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-06/analog/tan_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH3" objectDescription="NH3 ug/l" pvMin={0.0} pvMax={5000.0} x={310} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-06/analog/nh3_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH4" objectDescription="NH4 ug/l" pvMin={0.0} pvMax={5000.0} x={435} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-06/analog/nh4_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            {nh30102redCrossWhenComError}

            {/* aqs-06-81 and nh3-01-06 */}

            {/* Border for aqs-06-82 and nh3-01-07 */}
            <text x="742.5" y="200" fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >Well 3</text>

            <text x="742.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-82</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="550" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={615} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={740} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={865} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={677.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={802.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={615} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={740} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={865} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-82/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  

            {aqs0678redCrossWhenComError}

            <text x="742.5" y="635" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >NH3-01-07</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="550" y="650" width="385" height="250" ry="20"/>
            <SvgAnalog objectName="TAN" objectDescription="TAN mg/l" pvMin={0.0} pvMax={50.0} x={615} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-07/analog/tan_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH3" objectDescription="NH3 ug/l" pvMin={0.0} pvMax={5000.0} x={740} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-07/analog/nh3_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH4" objectDescription="NH4 ug/l" pvMin={0.0} pvMax={5000.0} x={865} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-07/analog/nh4_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            {nh30103redCrossWhenComError}

            {/* aqs-06-82 and nh3-01-07 */}

            {/* Border for aqs-06-83 and nh3-01-08 */}
            <text x="1172.5" y="200" fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >Well 2</text>

            <text x="1172.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-83</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="980" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={1045} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={1170} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={1295} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={1107.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={1232.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={1045} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={1170} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={1295} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-83/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            {aqs0679redCrossWhenComError}

            <text x="1172.5" y="635" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >NH3-01-08</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="980" y="650" width="385" height="250" ry="20"/>
            <SvgAnalog objectName="TAN" objectDescription="TAN mg/l" pvMin={0.0} pvMax={50.0} x={1045} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-08/analog/tan_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH3" objectDescription="NH3 ug/l" pvMin={0.0} pvMax={5000.0} x={1170} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-08/analog/nh3_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH4" objectDescription="NH4 ug/l" pvMin={0.0} pvMax={5000.0} x={1295} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-08/analog/nh4_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            {nh30104redCrossWhenComError}

            {/* aqs-06-83 and nh3-01-08 */}

            {/* Border for aqs-06-84 and nh3-01-09 */}
            <text x="1602.5" y="200" fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >Well 1</text>

            <text x="1602.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-84</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="1410" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={1475} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={1600} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={1725} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={1537.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={1662.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={1475} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={1600} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={1725} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-84/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            {aqs0680redCrossWhenComError}

            <text x="1602.5" y="635" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >NH3-01-09</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="1410" y="650" width="385" height="250" ry="20"/>
            <SvgAnalog objectName="TAN" objectDescription="TAN mg/l" pvMin={0.0} pvMax={50.0} x={1475} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-09/analog/tan_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH3" objectDescription="NH3 ug/l" pvMin={0.0} pvMax={5000.0} x={1600} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-09/analog/nh3_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="NH4" objectDescription="NH4 ug/l" pvMin={0.0} pvMax={5000.0} x={1725} y={830} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"nh3-01-09/analog/nh4_ugl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            {nh30105redCrossWhenComError}

            {/* aqs-06-84 and nh3-01-09 */}

          </svg>
        </div>
 
      </div>
    )

    }

}
